import React, { useMemo } from 'react';
import styles from './index.module.less';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { ga_logEvent } from '@/shared/ga';
import { EventName } from '@/shared/event-types';
import { pageMetadataMap, PageType } from '@/shared/app-common';
import Image from 'next/image';

const LoginStateInfo = ({ pageType }: { pageType?: PageType }) => {
  const { t } = useTranslation('common');
  const router = useRouter();
  const pageMetaData = pageMetadataMap[pageType ?? PageType.Normal];

  const list = useMemo(() => {
    return [
      t(`<p>Trusted by <span style='color:#725CFF'>15,000+</span> Users</p>`),
      t(`<p>Rich <span style='color:#725CFF'>Data</span></p>`),
      t(`<p><span style='color:#725CFF'>AI</span>-Powered</p>`),
      t(`<p>100% <span style='color:#725CFF'>Anonymous</span></p>`),
    ]
  }, []);
  const onDashboardClick = () => {
    router.push(`/dashboard?alertType=1`);
    ga_logEvent(EventName.home_ADD_ACCOUNT);
  };

  const getContent = useMemo(() => {
    return (
      <div className={styles.content}>
        <h1>
          {` ${t(pageMetaData.title1Key)} `}
          <br />
          {/* {<div className={styles.tracker}></div>} */}
        </h1>

        <p><span style={{ color: 'var(--app-text-color)', fontWeight: '600' }}>{t(pageMetaData.title2Key)}.</span> {t(pageMetaData.titleDescKey)}</p>

        <div className={styles.list}>
          {list.map((item, index) => {
            return <div key={index} className={styles.listItem}>
              <Image src={'/images/home/home-select-icon.webp'} alt='' width={17} height={12} />
              <p dangerouslySetInnerHTML={{ __html: item }} />
            </div>
          })}
          {/* <div>
            <span dangerouslySetInnerHTML={{__html: t(pageMetaData.introduces[0])}}>
          </div> */}
        </div>

        <div className={styles.btnContainer}>
          <Link className={styles.seeAnalyticsSample} href="/instagram-tracking-example">
            {t('SeeAnalyticsSample')}
          </Link>
          <span className={styles.goToDashboard} onClick={onDashboardClick}>
            {t('ADD ACCOUNT')}
          </span>
        </div>

      </div>
    );
  }, [pageMetaData, onDashboardClick]);

  return <>{getContent}</>;
};

export default LoginStateInfo;
